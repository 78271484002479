import { put, spawn, takeLatest } from 'redux-saga/effects';

import apiRequest from 'utils/api';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import { createUser, createUserFail, createUserSuccess } from './register.actions';

// Saga para registrar usuario
function* registerUser() {
  yield takeLatest(createUser, function* createRegularUser(action) {
    const { email, name, password, type, navigate } = action.payload;
    const body = JSON.stringify({ email, name, password, type });

    const response = yield apiRequest('api/v1/auth/register', { method: 'post', body });

    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(createUserFail());
    } else {
      defaultSuccessToast({ message: 'Usuario creado exitosamente.' });
      yield put(createUserSuccess());
      if (typeof navigate === 'function') navigate('/login');
    }
  });
}

// Registrar los watchers en el saga principal
export default function* RegisterSaga() {
  yield spawn(registerUser);
}
